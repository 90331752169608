.about-page {
    min-height: calc(100vh - 300px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about-page * {
    font-size: 24px;
}

.image {
    border-radius: 10px;
    width: 50%;
}

.container {
    width: 70vw;
    margin: 10px 30px 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

.container .list {
    display: flex;
    justify-content: space-between;
    align-items: start;
    width: 100%;
}

.container:last-of-type {
    margin-bottom: 30px;
}

.image-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
}

.heading {
    font-family: 'BlankRiver';
    font-size: 80px;
    margin: 0;
    text-align: center;
    text-decoration: underline;
}

.link {
    cursor: pointer;
    color: rgba(86,192,161,255);
}

.about-page ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}