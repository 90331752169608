@font-face {
    font-family: 'BlankRiver';
    src: url("./BlankRiver.ttf");
}

html,body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}

.App {
    min-height: 100vh;
    max-width: 100vw;
}