@media screen and (min-width: 600px) {
    .event-card {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        transition: .15s ease-in;
        opacity: 1;
        padding: 10px 40px 10px 40px;
    }

    .event-card:hover {
        border-radius: 20px;
        background-color: rgba(0, 0, 0, 0.2);
        transition: .15s ease-in;
    }

    .event-card .placeholder-image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100;
        height: 200px;
    }

    .event-card img {
        height: 200px;
        width: 200px;
        border-radius: 20px;
        object-fit: cover;
    }

    .event-card .description {
        display: flex;
        flex-direction: column;
        width: 50%;
        margin: 0 20px 0 40px;
        justify-content: center;
        align-items: flex-start;
    }

    .event-card .description h1 {
        width: 100%;
        border-bottom: 2px solid black;
        font-size: 38px;
        margin: 0 !important;
    }

    .event-card .attendees {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 16px;
    }

    .event-card-attending {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        transition: .15s ease-in;
        opacity: 1;
        padding: 10px 40px 10px 40px;
        background-color:rgba(86,192,161,255);
        border-radius: 20px;
    }

    .event-card-attending:hover {
        border-radius: 20px;
        background-color: rgba(0, 0, 0, 0.2);
        transition: .15s ease-in;
    }

    .event-card-attending .placeholder-image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100;
        height: 200px;
    }

    .event-card-attending img {
        height: 200px;
        width: 200px;
        border-radius: 20px;
        object-fit: cover;
    }

    .event-card-attending .description {
        display: flex;
        flex-direction: column;
        width: 50%;
        margin: 0 20px 0 40px;
        justify-content: center;
        align-items: flex-start;
    }

    .event-card-attending .description h1 {
        width: 100%;
        border-bottom: 2px solid black;
        font-size: 38px;
        margin: 0 !important;
    }

    .event-card-attending .attendees {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 16px;
    }
}

@media screen and (max-width: 600px) {
    .event-card {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        align-items: center;
        transition: .15s ease-in;
        opacity: 1;
    }
    
    .event-card:hover {
        border-radius: 20px;
        background-color: rgba(0, 0, 0, 0.2);
        transition: .15s ease-in;
    }
    
    .event-card .placeholder-image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 100px;
    }
    
    .event-card img {
        height: 100px;
        width: 100px;
        border-radius: 20px;
        object-fit: cover;
    }
    
    .event-card .description {
        display: flex;
        flex-direction: column;
        width: 70%;
        margin: 0 10px 0 20px;
        justify-content: center;
        align-items: flex-start;
    }
    
    .event-card .description h1 {
        font-size: 24px;
        margin: 0 !important;
    }

    .event-card .description h3 {
       font-size: 14px;
        margin: 0 !important;
    }

    .event-card .attendees {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 14px;
    }

    .event-card .attendees p {
        margin: 0 !important;
    }

    /* ATTENDING */

    .event-card-attending {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        align-items: center;
        transition: .15s ease-in;
        opacity: 1;
        border: 5px solid rgba(86,192,161,255);
        border-radius: 20px;
    }

    .event-card-attending:hover {
        border-radius: 20px;
        background-color: rgba(0, 0, 0, 0.2);
        transition: .15s ease-in;
    }

    .event-card-attending .placeholder-image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 100px;
    }

    .event-card-attending img {
        height: 100px;
        width: 100px;
        border-radius: 20px;
        object-fit: cover;
    }
    
    .event-card-attending .description {
        display: flex;
        flex-direction: column;
        width: 70%;
        margin: 0 10px 0 20px;
        justify-content: center;
        align-items: flex-start;
    }
    
    .event-card-attending .description h1 {
        font-size: 24px;
        margin: 0 !important;
    }

    .event-card-attending .description h3 {
       font-size: 14px;
        margin: 0 !important;
    }

    .event-card-attending .attendees {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 14px;
    }

    .event-card-attending .attendees p {
        margin: 0 !important;
    }
}