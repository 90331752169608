@font-face {
    font-family: 'BlankRiver';
    src: url("../../styles/BlankRiver.ttf");
}

@font-face {
    font-family: "Urban";
    src: url("../../styles/UrbanHeroes.ttf");
}

* {
    text-decoration: none;
}

.header {
    display: flex;
    height: 80px;
    justify-content: space-evenly;
    align-items: center;
    background-color: rgba(86,192,161,255) !important;
}

.header .dropdown {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 0 0 10px 10px;
    position: absolute;
    background-color: rgba(86,192,161,255);
}

.link {
    color: black;
    cursor: pointer;
    transition: .1s ease-out;
    font-family: "BlankRiver";
    font-size: 50px;
}

.link:hover {
    transition: .1s ease-in;
    color: rgb(73, 73, 73);
}

.hamburger-container {
    display: none;
}

.hamburger {
    display: none;
}

.logo {
    display: none;
}

@media screen and (max-width: 650px) {
    @keyframes growDown {
        0% {
            transform: scaleY(0)
        }
        80% {
            transform: scaleY(1.1)
        }
        100% {
            transform: scaleY(1)
        }
    }
    
    .logo {
        display: flex;
    }

    .hamburger {
        display: flex;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
    }

    .hamburger-container {
        display: flex;
    }

    .hamburger-menu {
        display: flex;
        height: auto;
        width: 100vw;
        justify-content: space-between;
        align-items: center;
        padding: 10px 30px;
        height: 80px;
        background-color: white !important;
    }

    .close-btn {
        position: fixed;
        top: 40px;
        right: 40px;
        cursor: pointer;
        font-size: 40px;
        transition: .1s ease-out;
    }

    .close-btn:hover {
        color: rgba(86,192,161,255);
        transition: .1s ease-in;
    }

    .hamburger-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 100vh;
        align-items: center;
        width: 100%;
        z-index: 10;
        background-color: rgb(153, 153, 153) !important;
        animation: growDown .3s ease-in-out forwards;
        transition: .1s ease-out;
    }

    .hamburger-header .link {
        font-size: 40px;
        transition: .1s ease-out;
    }

    .hamburger-header .link:hover {
        font-size: 40px;
        color: rgba(86,192,161,255);
        transition: .1s ease-in;
    }

    .header {
        display: none;
    }

    .link {
        font-size: 12px;
        color: black;
        cursor: pointer;
        transition: .1s ease-out;
        font-family: "BlankRiver";
        font-size: 80px;
    }
}