@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes fadeout {
    from {opacity: 1;}
    to { opacity: 0;}
}

.alert-box-closed {
    visibility: hidden;
    transition: .15s ease-out;
    animation: fadeout 2s ease;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 70px;
    margin: 0;
    margin-bottom: 2px;
    text-align: center;
    color: transparent;
}

.alert-box-open {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 70px;
    background-color: rgb(146, 146, 146);
    margin: 0;
    margin-bottom: 2px;
    text-align: center;
    transition: .15s ease-out;
    animation: fadein 2s ease;
    
}

.alert-box-open .close-btn {
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    height: 70px;
    width: 30px;
}

.alert-box-open .icon {
    height: 70px;
    cursor: pointer;
    margin: 0;
    background-color: black;
    color:white;
    border-radius: 0 20px 20px 0;
    font-size: 30px;
    width: 10vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "BlankRiver";
    transition: .1s ease-in;
}

.alert-box-open .close-btn:hover {
    transition: .1s ease-in;
    color: rgba(86,192,161,255) !important;
}

.alert-box-open .icon:hover {
    transition: .1s ease-in;
    color: rgba(86,192,161,255) !important;
}

.alert-box-open .message {
    width: calc(100% - 10vw);
    font-size: 6px;
    padding: 10px;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}

.alert-box-open .alert-container {
     display: flex;
     justify-content: center;
     align-items: center;
     width: 100%
}

.alert-box-open .alert {
    padding: 5px;
    color:white;
    font-size: 24px;
    width: auto;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    border-bottom: 10px solid;
    border-image-slice: 1;
    border-width: 5px;
    border-image-source: linear-gradient(to left, rgba(146, 146, 146), rgb(22, 141, 105) );
}

.alert-box-open a {
    color: white;
    transition: .1s ease-in;
}

.alert-box-open a:hover {
    color:black;
    transition: .1s ease-in;
}

@media screen and (max-width: 600px) {
    .alert-box-open {
        width: 100vw;
        display: flex;
        justify-content: space-between;
        background-color: rgb(146, 146, 146);
        font-size: 10px;
        height: 50px;
        text-align: center;
    }

    .alert-box-open .icon {
        background-color: black;
        height: auto !important;
        color:white;
        border-radius: 0;
        font-size: 20px;
        width: 10%;
        height: 50px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Urban";
    }

    
.alert-box-open .close-btn {
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 50px;
    cursor: pointer;
}

    .alert-box-open .message {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 5px;
    }
    
    .alert-box-open .alert {
        padding: 2px;
        font-size: 12px;
    }

}