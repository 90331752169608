@font-face {
    font-family: 'BlankRiver';
    src: url("../../styles/BlankRiver.ttf");
}

@font-face {
    font-family: "Urban";
    src: url("../../styles/UrbanHeroes.ttf");
}

.footer {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 150px;
    background-color: rgb(146, 146, 146);
    color: black;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.heading {
    font-size: 40px;
    font-family: 'BlankRiver';
    color: black;
}

.link {
    font-family: 'Urban';
    color: black;
    width: auto;
    transition: .1s ease-in;
}

.link:hover {
    color: white;
    transition: .1s ease-in;
}

.external-link {
    font-family: 'Urban' !important;
    color: black;
    transition: .1s ease-in;
}

.external-link:hover {
    color: white;
    transition: .1s ease-in;
}

.logo {
    display: none;
}

@media screen and (max-width: 600px) {
    .contiainer {
        padding: 0;
        margin: 0;
    }

    .logo {
        justify-self: center;
        align-self: center;
        display: flex;
    }
    
    .footer h1 {
        display: none;
    }
}