@font-face {
    font-family: 'BlankRiver';
    src: url("../../styles/BlankRiver.ttf");
}

@font-face {
    font-family: "Urban";
    src: url("../../styles/UrbanHeroes.ttf");
}

* {
    text-decoration: none;
    color: black;
}

.resin-page {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container {
    width: 80%;
    display: flex;
    flex-direction: column;
}

.image-container {
    max-width: 500px;
}

.container .centered-head {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container li {
    list-style: none;
    color: black;
}

.link {
    font-size: 80px;
    font-family: "BlankRiver";
    color: black !important;
    transition: 0.1s ease-in;
}

.resin-page a {
    color: rgba(86,192,161,255);
}

.link:hover {
    color:rgba(86,192,161,255) !important;
    cursor: pointer;
}

.line-break {
    width: 80%;
    border: 1px solid black;
}