@font-face {
    font-family: 'BlankRiver';
    src: url("../../styles/BlankRiver.ttf");
}

@font-face {
    font-family: "Urban";
    src: url("../../styles/UrbanHeroes.ttf");
}

.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 245px);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: contain;
    transition: .1s ease-in;
}

.home-container h1 {
    font-family: "BlankRiver";
}

.hero {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    border-radius: 20px;
    padding: 40px;
    color: black;
    font-family: "Urban";
    font-size: 25px;
}


.carousel {
    width: 30vw;
    border-radius: 20px;
}

.carousel img {
    border-radius: 20px;
}

.button-container {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.h4 {
    font-family: "BlankRiver";
    font-size: 50px;
    margin: 0;
}

@media screen and (max-width: 600px) {
    .home-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        min-height: calc(80vh - 80px);
        /* background:  linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url("../../images/FramePerfectControllers.png"); */
        background-position: center top;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .carousel {
        width: 60vw;
        margin-bottom: 20px;
    }    

    .hero {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        background-color: white;
        border-radius: 20px;
        padding: 20px;
        color: black;
        font-family: "Urban";
        font-size: 10px;
    }

    .h4 {
        font-family: "BlankRiver";
        font-size: 30px;
        margin: 0;
    }
}
